import { Alert, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import useAuth from "src/hooks/useAuth";

import fetchUser from "src/utils/fetchUser";
import { setSession } from "src/utils/jwt";

const ImpersonationBanner = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const isImpersonate = localStorage.getItem("isImpersonate");
  const goBackToAdmin = async () => {
    const params = {
      sub_admin_impersonate: localStorage.getItem("source_id") || null,
    };

    try {
      const {
        status,
        data: { access_token, menu_list },
      } = await fetchUser.get("back-to-admin", { params });
      if (status === 200) {
        localStorage.removeItem("profile");
        localStorage.setItem("isAdmin", true);
        localStorage.setItem("menu", JSON.stringify(menu_list));
        localStorage.removeItem("isSubAdmin");
        localStorage.removeItem("isImpersonate");
        setSession(access_token);
        window.location = `${window.origin}${sessionStorage.getItem(
          "impersonationSource"
        )}`;
      }
    } catch (err) {
      console.error(err);
    }
  };
  return isImpersonate ? (
    <Box>
      <Alert severity="info" sx={{ marginBottom: 2 }}>
        {t("impersonate.heads")} {user.username}
        <Button size="small" onClick={goBackToAdmin}>
          {t("impersonate.click")}
        </Button>
        {t("impersonate.toGo")}
      </Alert>
    </Box>
  ) : null;
};

export default ImpersonationBanner;
