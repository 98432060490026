import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet } from "react-router-dom";
import useIsUser from "src/hooks/use-is-user";
import useAuth from "src/hooks/useAuth";
import { PATH_DASHBOARD, PATH_USER } from "src/routes/paths";
import axiosInstance from "src/utils/axios";

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard() {
  const { i18n } = useTranslation();
  const { isAuthenticated, isAdmin } = useAuth();

  useEffect(() => {
    const getMenu = async (isAdmin = false) => {
      try {
        const { data, status } = await axiosInstance(`api/menu-list`, {
          params: { user_type: isAdmin ? "admin" : "user" },
          headers: {
            "Accept-Language": i18n.language,
          },
        });
        if (status) {
          localStorage.setItem("menu", JSON.stringify(data.data));
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (isAuthenticated) {
      getMenu(isAdmin);
    }
  }, [isAuthenticated, isAdmin]);

  const isUser = useIsUser();
  if (isAuthenticated) {
    if (isUser) {
      return <Navigate to={PATH_USER.root} replace />;
    }

    return <Navigate to={PATH_DASHBOARD.root} replace />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}
