import { Suspense, useState } from "react";
import { Outlet } from "react-router-dom";
import ImpersonationBanner from "src/components/impersonationBanner";
import useCollapseDrawer from "src/hooks/useCollapseDrawer";
import {
  DashboardHeader,
  HorizontalWrapper,
  MainStyle,
  NavbarVertical,
} from "../shared";
import { Box, Button } from "@mui/material";
import Iconify from "src/components/Iconify";

const Horizontal = ({ navConfig }) => {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  return (
    <HorizontalWrapper>
      <DashboardHeader
        isCollapse={isCollapse}
        onOpenSidebar={() => setOpen(true)}
      />

      <NavbarVertical
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
        config={navConfig}
      />

      <MainStyle collapseClick={collapseClick}>
        {/* <Box sx={{ textAlign: "right", mb: 1 }}>
          <Button
            variant="text"
            size="small"
            startIcon={<Iconify icon="ep:back" />}
            href="https://aiffilia.com/dashboard"
            sx={{ textTransform: "none" }}
          >
            Back to AI Dashboard
          </Button>
        </Box> */}
        <Suspense fallback={<>loading...</>}>
          <ImpersonationBanner />
          <Outlet />
        </Suspense>
      </MainStyle>
    </HorizontalWrapper>
  );
};

export default Horizontal;
